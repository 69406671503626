import React from "react"
import { Parallax } from 'react-scroll-parallax'

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const SendLinks = () => {
  const data = useStaticQuery(graphql`
    query PaperImageQuery {
      file(relativePath: {eq: "content_PaperAirplane.png"}) {
        childImageSharp {
          fluid {
            base64
            src
            aspectRatio
          }
        }
      }
    }
  `)

  return (
    <div className="page">
      <div className="page__content">
      <Parallax className="content__column-text" y={[0, -20]}>
            <h2>Envie seus links como quiser:</h2>
            <h3>SMS, Email, QrCode <br />ou apenas copie e cole.</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </Parallax>

        <Parallax className="content__column-image" y={[0, -20]} x={[0, 100]}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Crie Links para pagamentos em segundos" />
        </Parallax>
      </div>
    </div>
  )
}

export default SendLinks
